const FileUtils = {
  getVideoWidthAndHeight: async (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const width = video.videoWidth;
        const height = video.videoHeight;
        resolve({ width, height });
      };
      video.onerror = () => {
        window.URL.revokeObjectURL(video.src);
        reject();
      };
      video.src = URL.createObjectURL(file);
    });
  },
};

export default FileUtils;
