import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "./popup-stores.scss";
import ApiCalls from "../../api";
import ProductImage from "../../const/products-images";
import moment from "moment";
import DebounceInput from 'react-debounce-input';

const ProductDesc = (props) => {
    const [prodDescExpand, setProdDescExpand] = useState(false);
    const isMobile = window.innerWidth < 768;
    const canHide = props?.desc?.length > (isMobile? 70: 95);
    const _productDescExpand = (e) => {
        e.preventDefault();
        setProdDescExpand(!prodDescExpand);
    };
    return (
      <div
        className={"desc " + (prodDescExpand ? "expanded " : "") + (canHide? "canHide": "")}
        onClick={(e) => _productDescExpand(e)}
      >
        <span>{props.desc || ""}</span>
      </div>
    );
};
const _navigateToPopupStore = () => {
    setTimeout(() => {
      const element = document.getElementById("searchWrapper");
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        document.getElementById("searchPopupStore").focus();
      }
    }, 600);
  };
const PopupStores = () => {
    const [isLoaderActive, setIsLoaderActive] = useState(false);
    const [allPopUpStore, setAllPopUpStore] = useState([]);
    const popupStoreBaseURL = `/popup-store/`;
    const _loadPopupStoreData = async (storeName='') => {
        setIsLoaderActive(true);
        const popupStoreStats = await ApiCalls.popup.public.getAllPopupStore(storeName);
        let formattedProducts = popupStoreStats.data.data.popupStore || [];
        if(storeName.length >0){
            setAllPopUpStore(formattedProducts);
        }else{
            setAllPopUpStore([]);
        }
        setIsLoaderActive(false);
    };
   
    const handleKey = (event) => {
        _loadPopupStoreData(event.target.value);
    }
    useEffect(() => {
    }, []);
    return (
        <div className="popup-stores-page-wrapper">
            <Header />
            {isLoaderActive ? (
                <div className="loaderWrapper pageLoader">
                <div className="loader"></div>
                </div>
            ) : (
                <></>
            )}

            <div className="popup-stores-page">
                <div className="container">
                    <div className="bigHeadingSection">
                        <div className="bigHeading">Help young sport teams to support their dreams</div>
                        <hr className="divider" />
                        <div className="bigDesc">Every popup store represents a team supporter striving to fulfill dreams. Donate now, and 50% of profits go directly to the fundraising team. Explore our lively popup stores and experience the sweetness of fundraising. </div>
                        <div className="buttons">
                            <button className="button" onClick={_navigateToPopupStore}>Search for Popup Stores</button>
                        </div>
                    </div>



                    <div className="bigImageSection">
                        <div className="popupStoreScreenshot"><img src={require("../../assets/images/popupstore_screenshot.png")} alt="" /></div>
                        <div className="candyPic1"><img src={require("../../assets/images/candyPic1.png")} alt="" /></div>
                        <div className="candyPic2"><img src={require("../../assets/images/candyPic2.png")} alt="" /></div>
                        <div className="candyPic3"><img src={require("../../assets/images/candyPic3.png")} alt="" /></div>
                    </div>

                    <div className="searchWrapper" id="searchWrapper">
                        <div className="title">Explore LIVE popup stores</div>
                        <hr className="divider" />
                        <DebounceInput
                        minLength={2}
                        forceNotifyByEnter={false}
                        debounceTimeout={300}
                        onChange={handleKey}
                        className="form-control" 
                        id="searchPopupStore"
                        placeholder="Type store name or event organizer name ..."  />
                    </div>

                    <div className="popupStoreCards">
                        {allPopUpStore?.map((product) => (
                        <a href={`${popupStoreBaseURL}${product?.PopupCode}`} className="text-dark text-decoration-none">
                            <div className="popupStoreCard"  key={product.EventPopupID}>
                                <div className="inner">
                                    <div className="cardTop">
                                        <div className="imageWrapper">
                                            <ProductImage productName={product.Product} imageLink={product.imageLink} />
                                            </div>
                                        
                                        <div className="cardInfo">
                                            <div className="cardTitle">{product.PopupName} </div>
                                            <div className="eventName">{product.EventName}</div>

                                            <div className="goalBar">
                                                ${product?.totalSales} sold of ${product?.PopupGoal} 
                                                ({((product?.totalSales*100)/product?.PopupGoal).toFixed(2)}%) goal
                                                <div className="bar"><div style={{ width: `${(product?.totalSales*100)/product?.PopupGoal}%` }}></div></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cardBottom">
                                        <ProductDesc desc={product?.PopupDesc} />
                                    </div>
                                </div>
                            </div>
                        </a>
                        ))}
                        {!allPopUpStore.length && (<div>No Results Found</div>)}
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    );
};

export default PopupStores;
