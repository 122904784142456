import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CartUtils from "../../cart-utils";
import "./cart-final-checkout.scss";
import Swal from "sweetalert2";
// let hasShown = false;
const CartFinalCheckout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseNote, setPurchaseNote] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [paymentGatewayCharges, setPaymentGatewayCharges] = useState(0);
  const { selectedProducts, isCartExpanded, currentStep, selectedPopupStore } =
    useSelector((state) => state.cart);
  const totalCost = CartUtils.calculateCartTotal({
    withDelivery: false,
  });

  const _calculatePaymentGatewayCharges = (shippingCost) => {
    const totalCost = CartUtils.calculateCartTotal({
      withDelivery: false,
    });
    let paymentGatewayCharges = ((totalCost + shippingCost) * 3) / 100 + 0.3;
    // Upto 2 decimal places
    paymentGatewayCharges =
      Math.round((paymentGatewayCharges + Number.EPSILON) * 100) / 100;
    setPaymentGatewayCharges(paymentGatewayCharges);
  };

  const _processPayment = async () => {
    try {
      await CartUtils.submitPaymentOrder({
        shippingCost,
        purchaseNote,
        paymentGatewayCharges,
      });
    } catch (error) {
      // SHow error alert and reload the page on ok click. ANd no cancel button
      Swal.fire({
        icon: "error",
        title: "Problem shipping to the address",
        text: "Please try again. If the problem persists, please contact us.",
        showCancelButton: false,
        confirmButtonText: "OK",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const shippingCost = await CartUtils.fetchShippingCost();
      if (shippingCost > -1) {
        setShippingCost(shippingCost);
        _calculatePaymentGatewayCharges(shippingCost);
        setIsLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Cannot ship to the address",
          text: "Please provide a different zip code. Current one is outside of our network",
        });
        CartUtils.showCartAddressForm();
      }
    })();
  }, []);

  // Round off to 2 decimal places
  let totalCostWithShipping =
    Math.round(
      (totalCost + shippingCost + paymentGatewayCharges + Number.EPSILON) * 100
    ) / 100;

  if (isNaN(totalCostWithShipping)) {
    totalCostWithShipping = 0;
  }

  return (
    <>
      <div className={"inner-content payment-details step3 show"}>
        {isLoading ? (
          <div className="loaderWrapper">
            <div className="loader"></div>
          </div>
        ) : (
          <></>
        )}
        <div className="modal-title">
          <span class="title">Make payment</span>
          {/* close button */}
          <div className="close-modal" onClick={CartUtils.hideExpandedCart}>
            <svg
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L4.99998 19M5.00001 5L19 19"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          {/* /close button */}
        </div>

        <div className="total-things">
          <div className="item">
            <span className="label">Total cart amount: </span>
            <span className="value">
              <span>$ {totalCost}</span>
            </span>
          </div>
          <div className="item">
            <span className="label">Shipping charges: </span>
            <span className="value">
              <span>$ {shippingCost}</span>
            </span>
          </div>
          <div className="item">
            <span className="label">Payment processing fee: </span>
            <span className="value">
              <span>$ {paymentGatewayCharges}</span>
            </span>
          </div>

          <div className="item total">
            <span className="label">Total payable amount: </span>
            <span className="value">
              <span>$ {totalCostWithShipping}</span>
            </span>
          </div>
          {selectedPopupStore?.PremiumEvent === 0 && (
            <div className="purchaseNoteInputWrapper">
              <p>Purchase note</p>
              <textarea
                rows="5"
                value={purchaseNote}
                onChange={(e) => setPurchaseNote(e.target.value)}
              ></textarea>
            </div>
          )}

          <div className="bigActionButton">
            <span className="button proceed" onClick={_processPayment}>
              Proceed to payment
            </span>
            <span
              className="button continue"
              onClick={CartUtils.hideExpandedCart}
            >
              Continue shopping
            </span>
            <span
              className="button cancel"
              onClick={CartUtils.showCartProductsView}
            >
              Cancel payment
            </span>
          </div>
        </div>

        <div className="modal-buttons text-right">
          <span className="button prev" onClick={CartUtils.showCartAddressForm}>
            Previous
          </span>
        </div>
      </div>
    </>
  );
};

export default CartFinalCheckout;
