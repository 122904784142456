import { HttpClient } from "../clients/http-client";

const { ProtectedHttpClient, PublicHttpClient } = HttpClient;

const UserApi = {
  public: {
    getStripeKey: () =>PublicHttpClient.get(`/get-stripe-config`),
    createPaymentIntent: (payload) =>PublicHttpClient.post(`/create-payment-intent`, payload),
  },
  private: { },
};
export default UserApi;
