import React, { Component, useEffect, useState } from "react";
import ProductImage from "../../../../const/products-images";
import CartUtils from "../../cart-utils";

const CartProductView = ({
  productName,
  productPrice,
  productId,
  productQuantity,
  imageLink = "",
  isDonation = false,
}) => {
  const [donationValue, setDonationValue] = useState(50);

  const _updateDonationValueWithProperQuantity = () => {
    if (isDonation) {
      // Find quantity based on selected value and product price
      const quantity = donationValue / productPrice;
      // console.log('quantity :>> ', quantity);
      CartUtils.updateProductQuantity({
        productId: productId,
        quantity: quantity,
      });
    }
  };

  useEffect(() => {
    _updateDonationValueWithProperQuantity();
  }, [donationValue]);

  // Also update donation value when product quantity changes
  useEffect(() => {
    if (isDonation) {
      setDonationValue(productQuantity * productPrice);
    }
  }, [productQuantity]);
  return (
    <>
      <div className={`list-item ${isDonation && "donate-item"}`}>
        <div className="list-image-wrapper">
          {isDonation ? (
            <img src={require("../../../../assets/images/donate.png")} alt="" />
          ) : (
            <ProductImage productName={productName} imageLink={imageLink} />
          )}
        </div>

        <div className="list-details">
          <div className="title">{productName}</div>
          <div className="amount">${productPrice} / item</div>
        </div>

        {isDonation ? (
          <>
            <select
              name=""
              id=""
              className="form-control"
              onChange={(e) => setDonationValue(e.target.value)}
              value={donationValue}
            >
              <option value="50">$50</option>
              <option value="100">$100</option>
              <option value="150">$150</option>
              <option value="200">$200</option>
            </select>
            <div className="list-total-amount"></div>
          </>
        ) : (
          <>
            <div className="list-qty">
              <span
                className="decre"
                onClick={(e) =>
                  CartUtils.updateProductQuantity({
                    productId: productId,
                    quantity: productQuantity - 1,
                  })
                }
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 12H20"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span className="value">{productQuantity}</span>
              <span
                className="incre"
                onClick={(e) =>
                  CartUtils.updateProductQuantity({
                    productId: productId,
                    quantity: productQuantity + 1,
                  })
                }
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 12H20M12 4V20"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>

            <div className="list-total-amount">
              ${productQuantity * productPrice}
            </div>
          </>
        )}

        <div className="action">
          <span
            className="remove"
            onClick={(e) =>
              CartUtils.removeProduct({
                productId: productId,
              })
            }
          >
            Remove
          </span>
        </div>
      </div>
    </>
  );
};

export default CartProductView;
