import React, { Component, useEffect, useState } from "react";
import "./pwa-downloader.scss";
import { EventEmitter } from "../../utils/event-emitter";
const IconArrowUp = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4812 21.6611H14.5189C15.4545 21.6611 16.1597 21.424 16.6343 20.9498C17.1089 20.4756 17.3463 19.777 17.3463 18.854V9.06236C17.3463 8.13938 17.1089 7.4408 16.6343 6.96663C16.1597 6.49245 15.4545 6.25536 14.5189 6.25536H11.7897V7.4431H14.4944C15.0255 7.4431 15.4359 7.58382 15.7255 7.86525C16.0151 8.14668 16.1599 8.56521 16.1599 9.12084V18.7956C16.1599 19.3512 16.0151 19.7688 15.7255 20.0484C15.4359 20.328 15.0255 20.4678 14.4944 20.4678H3.50568C2.96364 20.4678 2.55055 20.328 2.26641 20.0484C1.98227 19.7688 1.8402 19.3512 1.8402 18.7956V9.12084C1.8402 8.56521 1.98227 8.14668 2.26641 7.86525C2.55055 7.58382 2.96364 7.4431 3.50568 7.4431H6.22111V6.25536H3.4812C2.54641 6.25536 1.84148 6.49063 1.36641 6.96118C0.891343 7.43175 0.653809 8.13214 0.653809 9.06236V18.854C0.653809 19.7843 0.891343 20.4846 1.36641 20.9552C1.84148 21.4258 2.54641 21.6611 3.4812 21.6611ZM8.9966 14.2796C9.15714 14.2796 9.29614 14.2232 9.41362 14.1103C9.5311 13.9974 9.58984 13.8606 9.58984 13.7001V3.38255L9.54352 1.98459L12.0232 4.55816C12.1302 4.6788 12.2663 4.73912 12.4313 4.73912C12.5801 4.73912 12.7076 4.68946 12.8137 4.59013C12.9198 4.49081 12.9728 4.36721 12.9728 4.21934C12.9728 4.07603 12.9121 3.94771 12.7905 3.83438L9.42112 0.534335C9.35126 0.459968 9.28163 0.408719 9.21224 0.380591C9.14285 0.352449 9.07097 0.338379 8.9966 0.338379C8.92316 0.338379 8.85266 0.352449 8.78509 0.380591C8.71752 0.408719 8.64652 0.459968 8.5721 0.534335L5.19732 3.83438C5.08026 3.94771 5.02173 4.07603 5.02173 4.21934C5.02173 4.36721 5.07162 4.49081 5.17141 4.59013C5.2712 4.68946 5.39953 4.73912 5.55641 4.73912C5.72152 4.73912 5.85803 4.6788 5.96594 4.55816L7.60019 2.86818L8.45644 1.97911L8.41022 3.38255V13.7001C8.41022 13.8606 8.46895 13.9974 8.5864 14.1103C8.70385 14.2232 8.84059 14.2796 8.9966 14.2796Z"
        fill="#0A84FF"
        stroke="#0A84FF"
        stroke-width="0.2"
      ></path>
    </svg>
  );
};
const PwaDownloader = () => {
  const [shouldShowGuide, setShouldShowGuide] = useState(false);

  const isSafari = !!window.GestureEvent;
  

  const _downloadPwa = () => {
    let shouldShowGuide = false;
    if (isSafari) {
      shouldShowGuide = true;
    } else {
      // Trigger pwa install
      if (window?.deferredPrompt?.prompt) {
        window.deferredPrompt.prompt();
      } else {
        shouldShowGuide = true;
      }
    }
    console.log("shouldShowGuide :>> ", shouldShowGuide);
    setShouldShowGuide(shouldShowGuide);
  };

  const _subscribeToEvents = () => {
    EventEmitter.subscribe("triggerPwaDownload", _downloadPwa);
  };

  const _unsubscribeToEvents = () => {
    EventEmitter.cancelAll("triggerPwaDownload");
  };

  useEffect(() => {
    _subscribeToEvents();
    return () => {
      _unsubscribeToEvents();
    };
  }, []);
  return (
    <>
      <div className={"downloadAppModal " + (shouldShowGuide ? "show" : "")}>
        <div className="modalInner">
          <div
            className="closeModal"
            onClick={(e) => setShouldShowGuide(false)}
          >
            <i className="fa fa-times"></i>
          </div>
          <div className="image-wrapper">
            <img src={require("../../assets/images/logo.png")} alt="" />
          </div>
          <div className="title">Get our app</div>
          <div className="desc">
            Download Treats Island onto your home screen for easy access
          </div>

          <div className="steps">
            {isSafari ? (
              <>
                <div className="step">
                  {" "}
                  1. Tap <IconArrowUp /> below{" "}
                </div>
                <div className="step">
                  {" "}
                  2. then <span>Add to Home Screen</span>
                </div>
              </>
            ) : (
              <>
                <div className="step">
                  {" "}
                  1. Tap <i class="fa fa-ellipsis-v threeDots"></i> at top-right
                  corner of the screen{" "}
                </div>
                <div className="step">
                  {" "}
                  2. then tap <span>Add to Home Screen</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PwaDownloader;
