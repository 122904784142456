import moment from "moment";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import Standard from "../../const/standards";
import "./share-button.scss";
import { result } from "lodash";
import ShowAppRedirectWarning from "../../features/native-share/components/show-app-redirect-warning/show-app-redirect-warning";
import { EventEmitter } from "../../utils/event-emitter";

const ShareButton = ({
  linkPart,
  messagePart,
  copyFullMessage = false,
  joinMessageWithLink = true,
  entity = "event",
  event = null,
  popupStore = null,
}) => {
  const [eventHolder, setEventHolder] = React.useState({
    functionToCall: null,
    params: {},
  });

  const [showShareInfoModal, setShowShareInfoModal] = React.useState(false);
  const [shouldShow, setShouldShow] = React.useState(false);
  const _checkIfMobile = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };
  const isMobile = _checkIfMobile();
  const { functionToCall, params } = eventHolder;
  const detectBrowserType = () => {
    if (window) {
      // Opera 8.0+
      const isOpera =
        // eslint-disable-next-line no-undef
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;
      // Firefox 1.0+
      const isFirefox = typeof InstallTrigger !== "undefined";
      // Safari 3.0+ "[object HTMLElementConstructor]"
      const isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && window["safari"].pushNotification)
        );
      // Internet Explorer 6-11
      const isIE = /*@cc_on!@*/ false || !!document.documentMode;
      // Edge 20+
      const isEdge = !isIE && !!window.StyleMedia;
      // Chrome 1 - 79
      const isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);
      // Edge (based on chromium) detection
      const isEdgeChromium =
        isChrome && navigator.userAgent.indexOf("Edg") != -1;
      // Blink engine detection
      const isBlink = (isChrome || isOpera) && !!window.CSS;
      return {
        isOpera,
        isFirefox,
        isSafari,
        isIE,
        isEdge,
        isChrome,
        isEdgeChromium,
        isBlink,
      };
    } else {
      return {};
    }
  };
  const _generateMessageAndLink = () => {
    const result = {
      message: "",
      link: "",
    };
    if (entity === "event") {
      const inviteLink = `${window.location.origin}/join-event?je=${event?.EventCode}`;

      const startDate = moment(parseInt(event?.StartDate)).format(
        Standard.dateFormat
      );
      const time = moment(parseInt(event?.StartDate)).format(
        Standard.timeFormat
      );
      const endDate = moment(parseInt(event?.EndDate))
        .format(Standard.dateFormat);
      result.link = inviteLink;
//       result.message = `Hello Team - I set up a virtual fundraiser with Treats Island Candy! It is 100% contactless. We get to keep 50% of total profit and Treat Island Candy will ship the product directly to our buyers.
    
// Each of us will create a Pop-Up Store selling this specialized candy! The prices range from $15 to $20 per container and you won't find these premium products in general stores.

// Our fundraising window begins on ${startDate}, at ${time} and goes until ${endDate}, at ${time}.

// Before the fundraiser begins:
// 1. Click on the LINK
// 2. Confirm the Event Code ${event?.EventCode}
// 3. Create your personalized Pop-Up Store

// SHARE WITH YOUR MEMBERS
// `;
result.message = `Hello Team - I set up a virtual fundraiser with Treats Island Candy! It is 100% contactless. We get to keep 50% of total profit and Treat Island Candy will ship the product directly to our buyers. Each of us will create a Pop-Up Store selling this specialized candy! The prices range from $15 to $20 per container and you won't find these premium products in general stores. Our fundraising window begins on ${startDate}, at ${time} and goes until ${endDate}, at ${time}. Before the fundraiser begins:

Click on the ${inviteLink}

Confirm the Event Code ${event?.EventCode} 

Create your personalized Pop-Up Store

*Please Note Your supporters can't purchase candy until the campaign begins.
**Do not forget to save the app to your phone once you start.
*** Gather phone #'s and/or emails of your network and forward your Popup store just before fundraiser begins.
`;
    } else {
      const popuplink = `${window.location.origin}/popup-store/${popupStore?.PopupCode}`;
      result.message = `Come buy candy and support my event: ${popuplink}`;
      result.message = `
      Hello. My team is doing a candy fundraiser. I have 5 days to raise funds. Please click on the link to open my virtual popup store and support my event. Thanks. Link: ${popuplink}
`;
      result.link = popuplink;
    }
    return result;
  };

  const { message, link } = _generateMessageAndLink();

  const _initiateMessage = () => {
    _copyMessage();
    const URL = `sms:?&body=${message}`;
    window.open(URL, "_blank");
  };
  const _initiateEmail = () => {
    _copyMessage();
    const URL = `mailto:?&body=${message}`;
    window.open(URL, "_blank");
  };
  const _copyMessage = () => {
    navigator.clipboard.writeText(`${message}`);
    toast("Copied to clipboard!");
  };
  const _copyLink = () => {
    navigator.clipboard.writeText(link);
    toast("Copied to clipboard!");
  };

  const _visitLink = () => {
    window.open(link, "_blank").focus();
  };
  const _shareOnSocialMedia = ({ medium = "COPY_LINK" }) => {
    const pageName = "Treats Island Candy";
    let finalLink = "";
    switch (medium) {
      case "WHATSAPP": {
        finalLink = `https://api.whatsapp.com/send?text=${pageName}&${link}`;
        break;
      }
      case "INSTAGRAM": {
        finalLink = `https://www.instagram.com/?url=${link}`;
        break;
      }
      case "FACEBOOK": {
        finalLink = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
        break;
      }
      case "TWITTER": {
        finalLink = `https://twitter.com/intent/tweet?text=${pageName}&url=${link}`;
        break;
      }
      case "LINKEDIN": {
        finalLink = `https://www.linkedin.com/shareArticle?mini=true&url=${link}`;
        break;
      }
      default: {
        finalLink = ``;
      }
    }
    window.open(finalLink, "_blank").focus();
  };

  const _isNativeShareSupported = () => {
    try {
      const browserTypes = detectBrowserType();
      return window?.navigator?.share && !browserTypes?.isEdgeChromium;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    // Subscribe to the event
    EventEmitter.subscribe("SUBMIT_SHARE_REDIRECT_MODAL", _onConfirm);
    EventEmitter.subscribe("CANCEL_SHARE_REDIRECT_MODAL", _onClose);
    return () => {
      // Unsubscribe the event
      EventEmitter.cancelAll("SUBMIT_SHARE_REDIRECT_MODAL");
      EventEmitter.cancelAll("CANCEL_SHARE_REDIRECT_MODAL");
    };
  }, [functionToCall]);

  const _onConfirm = () => {
    console.log("functionToCall 222121:>> ", functionToCall);
    functionToCall(params);
    // Close the modal
    // setShowShareInfoModal(false);
  };

  const _onClose = () => {
    // Close the modal
    setEventHolder({
      functionToCall: null,
      params: {},
    });
    // setShowShareInfoModal(false);
  };

  const isNative = _isNativeShareSupported();

  const _share = ({
    functionToCall = null,
    params = {},
    shouldShowAppRedirectWarning = false,
  }) => {
    const doNotShowAgain = localStorage.getItem("doNotShowAgain");
    const isMobile = _checkIfMobile();
    if (shouldShowAppRedirectWarning && doNotShowAgain !== "true" && isMobile) {
      console.log("functionToCall :>> ", functionToCall);
      setEventHolder({
        functionToCall,
        params,
      });
      EventEmitter.dispatch("SHOW_SHARE_REDIRECT_MODAL");
    } else {
      // Call directly
      functionToCall(params);
    }
  };

  const _nativeShare = ({ message = "", link = "" }) => {
    if (isNative) {
      window.navigator.share({
        title: "Treats Island Candy",
        text: message,
        url: link,
      });
    }
  };

  return (
    <>
      <div className="buttonOuterWrapper">
        <button>
          <i className="fa-solid fa-share-nodes"></i> <span>Share</span>
        </button>
        <div className="shareOptions">
          {isMobile ? (
            <></>
          ) : (
            <>
              {entity !== "event" ? (
                <>
                  <div
                    className="option"
                    onClick={(e) =>
                      _share({
                        functionToCall: _initiateEmail,
                        params: {},
                        shouldShowAppRedirectWarning: true,
                      })
                    }
                  >
                    <i className="fa-solid fa-envelope"></i>&nbsp;Invite via
                    Email
                  </div>
                  <div
                    className="option"
                    onClick={(e) =>
                      _share({
                        functionToCall: _initiateMessage,
                        params: {},
                        shouldShowAppRedirectWarning: true,
                      })
                    }
                  >
                    <i className="fa-solid fa-comment-sms"></i>&nbsp;Invite via
                    Text
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {/* <div className="option">
            <i className="far fa-copy"></i>&nbsp;Share invite message
          </div> */}

          {entity === "popup" ? (
            <>
              {isMobile ? (
                <></>
              ) : (
                <>
                  <div
                    className="option"
                    // onClick={(e) => _shareOnSocialMedia("FACEBOOK")}
                    onClick={(e) =>
                      _share({
                        functionToCall: _shareOnSocialMedia,
                        params: { medium: "FACEBOOK" },
                        shouldShowAppRedirectWarning: true,
                      })
                    }
                  >
                    <i className="fa-brands fa-facebook"></i>&nbsp;Share on
                    facebook
                  </div>
                  <div
                    className="option"
                    onClick={(e) =>
                      _share({
                        functionToCall: _shareOnSocialMedia,
                        params: { medium: "TWITTER" },
                        shouldShowAppRedirectWarning: true,
                      })
                    }
                  >
                    <i className="fa-brands fa-twitter"></i>&nbsp;Share on
                    twitter
                  </div>
                </>
              )}
              <div
                className="option"
                onClick={(e) =>
                  _share({
                    functionToCall: _visitLink,
                    shouldShowAppRedirectWarning: false,
                    params: {},
                  })
                }
              >
                <i className="fa-solid fa-link"></i>&nbsp;Visit Popup Store
              </div>
            </>
          ) : (
            <>
              
              <div
                className="option"
                onClick={(e) =>
                  _share({
                    functionToCall: _copyLink,
                    params: {},
                  })
                }
              >
                <i className="fa-solid fa-copy"></i>&nbsp;Copy and share entire
                link
              </div>
            </>
          )}
          <div
                className="option"
                onClick={(e) =>
                  _share({
                    functionToCall: _copyMessage,
                    params: {},
                  })
                }
              >
                <i className="fa-solid fa-copy"></i>&nbsp;Copy and share entire
                message
              </div>
          {isMobile && isNative ? (
            <div
              className="option"
              onClick={(e) =>
                _share({
                  functionToCall: _nativeShare,
                  params: {
                    message: message,
                    link: link,
                  },
                  shouldShowAppRedirectWarning: true,
                })
              }
            >
              <i className="fa-solid fa-mobile"></i>&nbsp;Share via mobile
            </div>
          ) : (
            // <RWebShare
            //   data={{
            //     text: message,
            //     url: link,
            //     title: "Treats island",
            //   }}
            // >
            //   <div className="option">
            //     <i className="fa-solid fa-mobile"></i>&nbsp;Share via mobile
            //   </div>
            // </RWebShare>
            <></>
          )}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default ShareButton;
