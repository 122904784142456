import React, { Component } from "react";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory(false);

  const _navigateToVirtual = () => {
    history.push("/home");
    setTimeout(() => {
      const element = document.getElementById("virtual-fundraising");
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 400);
  };

  const _navigateToAboutUs = () => {
    history.push("/home");
    setTimeout(() => {
      const element = document.getElementById("aboutUs");
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 400);
  };

  const _composeSupportEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <>
      <div className="site-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-5 mb-lg-0 logo">
              <img src={require("../assets/images/logo.png")} alt="" />
            </div>

            <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
              <div className="ftrTitle">Contact us</div>

              <div className="ftrDesc">
                <div className="item small mb-4">
                  <span className="fas fa-map-marker-alt mr-2"></span>
                  <b>Address</b> <br /> 10808 Foothill Blvd Suite 160-580 Rancho
                  Cucamonga, CA 91730
                </div>
                <div
                  className="item small mb-4"
                  onClick={(e) =>
                    _composeSupportEmail("Support@TreatsIslandvf.com")
                  }
                >
                  <span className="far fa-envelope mr-2"></span>
                  <b>Email</b> <br /> Support@TreatsIslandvf.com
                </div>
                <div className="item small mb-4">
                  <span className="fas fa-phone mr-2"></span>
                  <b>Office number</b> <br /> 877.404.5656
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
              <div className="ftrTitle">Quick links</div>

              <div className="ftrMenuWrap">
                <ul className="ftrMenu">
                  <li onClick={_navigateToAboutUs}>About us</li>
                  <li onClick={_navigateToVirtual}>Virtual fundraising</li>
                  <li onClick={(e) => history.push("/shop-premium-candy")}>
                    Shop Direct!
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
              {/* <div className="ftrTitle">FAQs</div>

                            <div className="ftrMenuWrap">
                                <div id="accordion">
                                    <div className="card bg-transparent">
                                        <div className="card-header bg-transparent border-none collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" id="headingOne">Question #1</div>

                                        <div id="collapseOne" className="answer collapse small show" aria-labelledby="headingOne" data-parent="#accordion">
                                            Answer goes here
                                        </div>
                                    </div>
                                    <div className="card bg-transparent">
                                        <div className="card-header bg-transparent border-none collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" id="headingTwo">Question #2</div>

                                        <div id="collapseTwo" className="answer collapse small" aria-labelledby="headingTwo" data-parent="#accordion">
                                            Answer goes here
                                        </div>
                                    </div>
                                    <div className="card bg-transparent">
                                        <div className="card-header bg-transparent border-none collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" id="headingThree">Question #3</div>

                                        <div id="collapseThree" className="answer collapse small" aria-labelledby="headingThree" data-parent="#accordion">
                                            Answer goes here
                                        </div>
                                    </div>
                                </div>

                            </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 text-center text-md-left">
              <span className="copyText">
                Copyright &copy; {new Date().getFullYear()} Treats Island. All
                rights reserved.
              </span>
            </div>

            <div className="col-md-8 text-center text-md-right mt-4 mt-md-0 d-flex justify-content-end align-items-center">
              {/* <div className="contact-details small d-flex justify-content-end align-items-center">
                                <div className="item address mr-3">
                                    <span className="fas fa-map-marker-alt"></span> 10808 Foothill Blvd Suite 160-580 Rancho Cucamonga, CA 91730
                                </div>
                                <div className="item address mr-3">
                                    <span className="fa fa-envelope"></span> email
                                </div>
                            </div> */}

              <ul className="ftrSocial justify-content-center justify-content-md-end">
                <li>
                  <a
                    href="https://www.facebook.com/people/Treatsislandvf/100093527407023/"
                    target="_blank"
                    className="text-light"
                    >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/treatsislandvf/"
                    target="_blank"
                    className="text-light"
                    >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/treatsislandvf?s=11&t=WVh9g71mIEe2ldk4gxM3_A"
                    target="_blank"
                    className="text-light"
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                {/* <li><i className="fa-brands fa-facebook-f"></i></li>
                                <li><i className="fa-brands fa-dribbble"></i></li>
                                <li><i className="fa-brands fa-tumblr"></i></li>
                                <li><i className="fa-regular fa-envelope"></i></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
