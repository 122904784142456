import React, { useEffect } from "react";
import { EventEmitter } from "../../../../utils/event-emitter";

const ShowAppRedirectWarning = () => {
  const [show, setShow] = React.useState(false);

  const _subscribeToEvents = () => {
    EventEmitter.subscribe("SHOW_SHARE_REDIRECT_MODAL", () => {
      setShow(true);
    });
  };

  const _unsubscribeToEvents = () => {
    EventEmitter.cancelAll("SHOW_SHARE_REDIRECT_MODAL");
  };

  const _onConfirm = () => {
    setShow(false);
    EventEmitter.dispatch("SUBMIT_SHARE_REDIRECT_MODAL");
  };

  const _onCancel = () => {
    setShow(false);
    EventEmitter.dispatch("CANCEL_SHARE_REDIRECT_MODAL");
  };

  const _handleDoNotShowAgain = (e) => {
    // If the user has selected the checkbox, then save it in local storage
    // If the user has not selected the checkbox, then remove it from local storage
    if (e.target.checked) {
      localStorage.setItem("doNotShowAgain", true);
    } else {
      localStorage.removeItem("doNotShowAgain");
    }
  };

  useEffect(() => {
    _subscribeToEvents();
    return () => {
      _unsubscribeToEvents();
    };
  }, []);
  return (
    <div className={"shareInfoModal " + (show ? "show" : "")}>
      <div className="modalInner">
        <div className="closeModal" onClick={_onCancel}>
          <i className="fa fa-times"></i>
        </div>

        <div className="image-wrapper">
          <img
            src={require("../../../../assets/images/share-img.png")}
            alt=""
          />
        </div>

        <div className="big">Wait 🤚</div>

        <div className="desc">
          <p>
            You'll be redirected to a different tab if you continue from here.
          </p>
          <p>
            After you've shared the link, you'll need to switch apps to get back
            to Treats Island.
          </p>
        </div>

        <div className="checkbox">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={false}
              id="doNotShowAgain"
              onClick={(e) => _handleDoNotShowAgain(e)}
            />
            <label className="form-check-label" for="doNotShowAgain">
              Do not show again
            </label>
          </div>
        </div>

        <div className="buttons">
          <div className="btn btn-light" onClick={_onCancel}>
            Cancel
          </div>
          <div className="btn btn-primary" onClick={_onConfirm}>
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowAppRedirectWarning;
